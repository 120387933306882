.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  //height: 400px;
  background-color: white;
  margin-left: -300px;
  margin-top: -200px;
  padding: 20px;
  z-index: 1050;

  box-shadow: 0 -1px 2px 1px rgba(0,0,0,0.1);

  border:1px solid @gray-borders;
  border-radius: 2px;

  .modal-container {
    .modal-header {
      overflow: auto;

      h1 {
        margin-top: 0;
        font-weight: 300;
        font-size: 1.75em;
      }

      i.fa-times {
        color: #ddd;
        cursor: pointer;
        transition: color 0.3s ease-out;
        &:hover {
          color: darken(#ddd, 10%);
        }
      }
    }

    .modal-body {

    }

    .modal-footer {
    }

  }
}

.backdrop {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index:1000;
}

// Transitions
// 1. Modal
.modal-enter {
  top: 0%;
  opacity: 0.01;
}

.modal-enter.modal-enter-active {
  top:50%;
  opacity: 1;
  transition: opacity .45s ease-in, top .4s ease-in;
}

.modal-leave {
  top:50%;
  opacity: 1;
}

.modal-leave.modal-leave-active {
  top: 0%;
  opacity: 0.01;
  transition: opacity .45s ease-in, top .4s ease-in;
}

// 2. Backdrop
.backdrop-enter {
  opacity: 0.01;
}

.backdrop-enter.backdrop-enter-active {
  opacity: 1;
  transition: opacity .45s ease-in;
}

.backdrop-leave {
  opacity: 1;
}

.backdrop-leave.backdrop-leave-active {
  opacity: 0.01;
  transition: opacity .45s ease-in;
}

// Share Modal
.shareModalContent {
  p {
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  textarea, input {
    margin-bottom: 0.5em;
  }

  div.social-media button {
    position: relative;
    z-index: 999;
  }
}

// devices smaller than the modal width + nice margin
// mobile
@media all
    and (max-width : 620px){

.modal {
  width: 85%;
  margin-left: -42.5%;
  height: auto;
  max-height: 80vh;
  overflow: auto;
}

.modal .modal-container .modal-header h1 {
  font-size: 1.5em;
  max-width: 80%;
}
}

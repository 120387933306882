.footer {
  position: absolute;
  line-height: 1.25em;
  bottom: 0;
  left: 0;
  width: 65%;
  //height: 24px;
  background: white;
  //color: #555;
  font-size: 0.95em;
  padding: 10px 30px 20px;
  border-top: 1px solid #eee;

  a {
    color: black;
  }
}

#player-component {
  width:35%;
  position: fixed;
  top: @searchbar-height;
  right: 0;
  float:right;
  max-height: calc(~"100vh - 58px");
  background:@gray-lightest;
  overflow:auto;
  z-index: 990;


  .app-container-sync & {
    width: 25%;
    right: 25%;
  }

  border-bottom: 1px solid @gray-borders;
  // border-left: 1px solid #eaeaea;

  transform: translateZ(0);

  .players {
    position: relative;
    min-height: 250px;

    .youtube-player-container {
      position: relative;
      overflow: hidden;

      .curtain {
        background: @gray-lightest;
        opacity: 0.85;
        width: 101%;
        height: 250px;
        position: absolute;
        margin-left: -1px;
      }

      #youtube-player {
        margin:0px auto;
        display:block;
        width: 100%;
        height: 250px;
      }
    }

    .soundcloud-player {
      width: 100%;
      padding-top: 45px;

      iframe {
        width: 100%;
        border: 0;
      }
    }
  }

  .playlist-toolbar {
    margin: 15px 2px 6px;
    color:#333;
    position: relative;
    text-align: center;

    padding: 5px 20px;

    // &:hover {
    //   color: black;
    // }
    // &:active {
    //   box-shadow:inset 0 0 50px 50px rgba(0, 0, 0, 0.1);
    // }

    .disabled {
      color: #9f9f9f !important;
      &:active { box-shadow: 0 0 0 rgba(0,0,0,0); }
    }

    .btn-group {
      margin: 0 2px;

      &:first-child {margin-left: -2px}
      &:last-of-type {margin-right: -2px}
    }
  }

  ul.playlist {
    clear:both;
    list-style:none;
    margin:0; padding:0;
    display: block;
    overflow: hidden;

    padding: 0 20px;

    transition: height 250ms ease-out;
    li {
      display: block;
      margin: 3px 0;
    }
    li a {
      display: block;
      padding:6px 0 0;
      background: #fff;
      height: 42px;
      color: black;
      border-bottom:4px solid transparent;
      position:relative;
      cursor:pointer;

      border-bottom: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;

      transition: height 300ms ease-out;

      &:focus {
        outline: 0;
        background: #f2f2f2;
      }

      span {
        display:inline-block;
        transition: background 500ms ease-out;
        background:transparent;
        &:active {
          background: #eee;
        }
      }

      span.bars,
      span.delete {
        color: #aaa;
        padding: 6px 10px;
        border-radius: 100%;
        position:absolute;
        z-index:100;

        &:hover {
          i { color:#888; }
        }
      }

      span.title {
        font-size:0.9em;
        color: inherit;
        padding: 6px;
        margin-left:32px;
        max-width:75%;
        white-space: nowrap;
        overflow: hidden;
      }

      span.pos {
        color: #aaa;
        margin-right: 4px;
        &:after {
          content: '.';
        }
      }

      span.bars {
        overflow: hidden;
        cursor:move;
        left:2px;
        &:active {
          background:transparent;
        }
      }


      span.delete {
        right:2px;

        &:hover {
          i { color:@red; }
        }

        &:active {
          background: lighten(@red, 35%);
        }
      }

      span.drop-zone {
        display: inline-block;
        position: absolute;
        width:100%;
        left:0;
        height: 24px;
        bottom: 0;
        background:transparent;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

    } // li

    li.ghost-playlist-item {
      background: #cfcfcf;
      height: 42px;
      display: block;
      border-top: 1px solid #bfbfbf;
      border-left: 1px solid #bfbfbf;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    // li.dragged-over {
    //   //border-bottom:4px solid @primary;
    //   margin-bottom: 58px;
    //   span.drop-zone { height: 150px; }
    // }

    li a.active {
      background: @accent;
      color:white;
      span.bars,
      span.delete {
        color: #fff;
        &:hover {
          i { color:darken(@accent, 25%); }
        }
      }

      &:focus {
        background: darken(@accent, 5%);
      }

      span.delete {
        display:none;
      }

      span.title {
        &:active {
          background: darken(@accent, 17.5%);
        }
      }

      span.pos {
        color: white;
      }
    }

    li a.old {
      background: transparent;
      border-bottom: 0px;
      border-right: 0px;
      &:focus {
        background: #e6e6e6;
      }
    }

    li.dragged {
      display:none;
      // height: 3px;
      // opacity:0.75;
      // overflow:hidden;
    }
  }

    .keyboard-shortcuts {
      font-size: 0.85em;
      padding: 10px 25px;
      color: #999;
      line-height: 1.65em;
      strong { font-weight: 500; }
      h4 { 
        font-size: 1.1em; 
        line-height: 1.5em;
        color: #aaa;
        margin: 0 0 0.65em 0;
        vertical-align: middle;
        i { margin-left: 4px; }
      }
      p { margin: 0.65em 0; }
      .keyboard-key {
        padding: 2px 7px;
        border: 1px solid #cfcfcf;
        border-radius: 2px;
      }
    }

}

@-webkit-keyframes animate-background {
  0% { background-position:50% 50%; }
  25% { background-position:10% 35%; }
  75% { background-position:90% 75%; }
  100% { background-position:50% 50%; }
}

#playlist-config {
  margin-top: 52px;
  margin-bottom: 0px;
  min-height: 0px;
  display: block;

  div.panel {
    overflow: hidden;
    background: -webkit-linear-gradient(#666 0%, #444 70%);
    background: -o-linear-gradient(#666 0%, #444 70%);
    background: linear-gradient(#666 0%, #444 70%);

    position: relative;

    min-height: 250px;

  } // panel

  .panel-bg {
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    background-repeat: no-repeat;
    background-size: 135%;
    background-position: 75% 50%;
    //transition: background-position 100ms ease-in;
    z-index: 1;
    -webkit-filter: blur(15px);
    opacity: 0.8;

    // -webkit-animation: 200s linear 2s infinite normal animate-background;

    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .editable {
    &.with-border,
    & .with-border {
      border-bottom: 1px dotted #cfcfcf;
    }

    &:hover {
      position: relative;
      cursor: pointer;

      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f044";
        margin-left: 6px;
        font-weight: 300;
        font-size: 12px;
        padding: 0 6px;
        color: #aaa;
        vertical-align: middle;
      }
    }
  }

  h2.editable:after {
    line-height:22px;
  }

  .id-field {
    display:block;

    h3 {
      display: inline-block;
      margin: 0;
    }

    &:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-left: 4px;
      font-size: 1em;
    }

    &.available:after {
      color: green;
      content: "\f00c";
    }

    &.taken:after {
      color: red;
      content: "\f00d";
    }
  }
}

.left-panel {
  width:65%;
  color: white;
  overflow: visible;
  position: relative;
  padding: 2em 40px 1em;
  min-height: 250px;

  .app-container-sync & {
    width: 50%;
  }

  button {
    font-size: 0.8em;
    vertical-align: bottom;
  
    &.delete-button {
      color: white;
      background: #d60808;
      border-color: darkred;
    }
  }

  div.img-container {
    position: relative;
    float:left;
    width: 22.5%;
    margin: 0 2.5% 0 0;

    img {
      max-width: 100%;
      max-height: 162px;
      transition: opacity 150ms ease-out;
    }

  }

  div.img-container-inside {
    display: inline-block;
    position: relative;

    &.editable-image {
      cursor: pointer;

      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: white;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display: block;
        content: "\f021";
        line-height: 137px;
        text-align: center;
        font-size: 2em;
        opacity: 0;
        transition: opacity 150ms ease-out;
      }

      &:hover {
        &:after{ opacity: 1; }
        img { opacity: 0.65; }
      }
    }
  }

  .left-panel-contents {
    float: left;
    width: 75%;

    input[type="text"] {
      width: 125px;
    }

    button {
      &:last-child {
        // color: #bfbfbf;
      }
    }

    .left-panel-top {
      float:left;
      width: 75%;

      h2 {
        display: inline-block;
        margin: 0;
        font-weight: 300;
        font-size: 1.9em;
      }

      h3 {
        display: inline-block;
        margin: 0;
        font-weight: 200;
        line-height: 28px; // so it centers on the input
      }

      .left-panel-title {
        min-height: 30px;
        margin: 0 0 12px 0;

        input {
          min-width: 250px;
        }
      }

      .left-panel-link {
        min-height: 30px;
        line-height: 28px;
        a {
          color: #aaa;
          font-weight: 300;
          font-size: 0.85em;
          text-decoration: underline;
        }
        a:hover {
          text-decoration: none !important;
        }
      }

      .left-panel-description {
        color: #cfcfcf;
        margin-bottom: 24px;

        p {
          display: inline-block;
          margin: 0;
        }
        button {
          vertical-align: top;
        }
        textarea {
          width: 65%;
          height: 50px;
        }
        a {
          color: #cfcfcf;
        }
      }

      .left-panel-user,
      .left-panel-url {
        margin: 12px 0;
        min-height: 30px;

        input {
          width: 100px;
        }

        a {
          color: white;
        }
      }
    }

    .left-panel-bottom {
      float:left;
      width: 25%;
      text-align: right;

      p.subtitle {
        margin: 0 0 6px 0;
        color: #cfcfcf;
        font-weight: 300;
        font-size: 0.85em;
      }

    }

    button + button {
      border-left: 0;
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    button:first-of-type {
      margin-left: 3px;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .left-panel-buttons {
      button {
        width: 100px;
        border-radius: 2px !important;
      }
      p:first-child { margin-top: 0; }
      p {
        margin-bottom: 16px;
      }
    }

  } // left-panel-contents
} // left-panel

// .right-panel {
//   float: right;
//   width: 20%;
//   text-align: right;

//   ul {
//     list-style: none;

//     li {
//       margin-bottom: 0.5em;
//       display: block;
//       button {width:100px}
//       input {margin-left:8px}
//       label {cursor: pointer}
//     }
//   }
// } // right-panel

.left-panel,
.right-panel {
  position: relative;
  z-index: 2;
}

ul.tags {
  list-style: none;
  color: #fafafa;
  font-size: 0.8em;
  font-weight: 500;
  padding: 0;
  display: inline;

  li {
    display: inline-block;
    margin: 0 3px 3px 0;
    padding: 3px 6px;
    background: #aaa;
    border-radius: 2px;

    .icon-container {
      margin-left: 8px;
      color: #cfcfcf;
      cursor: pointer;

      &.icon-container-up {
        &:hover {
          color: white;
        }
      }

      &.icon-container-down {
        &:hover {
          color: white;
        }
      }

    }
  }
}

a.add-tag {
  padding: 0px 12px;
  background: #aaa;
  border-bottom: 2px solid #999;
  //border-top: 1px solid #ccc;
  border-radius: 2px;

  cursor: default;

  color: white;
  text-decoration: none;

  display: inline-block;
  height: 20px;
  position: relative;
  //top: 2px;

  &:hover {
    background: #afafaf;
  }
}

.tags-input-container {
  input, button {
    vertical-align: bottom;
  }
  position: relative;
  top:1px;
}

.button-tooltip {
  background: red;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  vertical-align: top;
}

@inputWidth: 60%;

#search-component {
  float:left;
  padding:0 0px;
  padding-bottom: 75px;
  width:65%;
  background: @gray-lightest;
  border-right: 1px solid @gray-borders;

  .app-container-sync & {
    width: 50%;
  }

  button {
    font-weight: 400;
  }

  .desktop-join-pl {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height:30px;
    z-index: 9999;

    transition: transform 250ms ease-out;
    will-change: transform;

    transform: scale(1, 0);
    transform-origin: top center;

    background: #333339;
    color: #eaeaea;
    text-align: center;
    line-height: 30px;

    font-size:0.9em;
    font-weight: 500;

    a {
      color: white;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }

    a.hide-message {
      float:right;
      text-decoration: none;
      color: #999;
      margin-right: 8px;

      &:hover {
        color: #aaa;
      }
    }

    &.in {
      transform: scale(1, 1);
    }

  }

  .desktop-join-pl.in + form {
    top: 30px;
  }

  form.topbar-form {
    .topBar();
    transition: top 250ms ease-out;
    will-change: top;

    .tagSpan {
      height: @searchbar-height - 4;
      margin-top: 2px;
      border: 0;
      background: @primary;
      display: inline-block;
      //border-radius: 0;
      color: white;
      vertical-align: top;

      i.pre {
        margin-right: 6px;
      }

      &:hover {
        background:@lighter-primary;
      }

    }

    .queryInputContainer {
      position: relative;
      width: @inputWidth;
      display: inline-block;
      vertical-align: middle;
    }

    .clear-search {
      position: absolute;
      top: 10px;
      right: 280px;
      left: auto;
      margin-left: -20px;
      color: black;
      display: none;
      color: @gray-light;
      transition: color 250ms ease-out;
      z-index: 1050;

      &.in {
        display: inline-block;
        &:hover {
          color: black;
          cursor: pointer;
        }
      }
    }

    input[type="text"].q, .textarea-container {
      height: @searchinput-height;
      flex-grow: 1;
      vertical-align: middle;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .textarea-container {
      position: relative;
      display: inline-block;
    }

    textarea.q {
      width: 100%;
      max-width: 100%;
      height: 32px;
      margin-right: 4px;
      transition: height 150ms ease-out;
      will-change: height;
    }

    textarea.q:focus {
      height: 125px;
    }

    input[type="submit"] {
      height: @searchinput-height;
      width: 15%;
      max-width: 135px;
      margin-left: -2px;
      border:1px solid @gray-borders;
      background: @gray-lightest;
      border-radius:2px;
      position: relative;
      font-weight:400;
      vertical-align: middle;
      transition: background 200ms ease-in;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:hover {
        background: darken(@gray-lightest, 3%);
      }

    }

    button.expanded-search {
      border: 0;
      color: white;
      background: transparent;
      text-decoration: underline;
      padding: 0 20px;
      max-width: 135px;
    }

    button.expanded-lucky {
      display: none;
    }

    input[type="checkbox"] {
      margin-left: 2%;
      margin-top:0px;
      vertical-align: middle;

      & + label {
        font-size: 0.9em;
      }
    }

    div.toolbox {
      float:right;
      display: inline-block;
    }

    button.action-button {
      .tagSpan();
      background: transparent;
      cursor: pointer;

      &:last-child {
        margin-right:0;
      }

      &.active {
        background: @accent;
        border-color: darken(@accent, 6.5%);
      }

      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display:inline-block;
        width:20px;
        text-align: left;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }

      &.save:before {
        content: "\f0c7";
      }

      &.save.saved:before {
        -webkit-animation: saved 500ms ease-out 0s 1 normal forwards;
        animation: saved 500ms ease-out 0s 1 normal forwards;
        -webkit-transform-origin: 5px 6px;
        transform-origin: 5px 6px;
        content: "\f00c";
      }

      &.sync:before {
        content: "\f2f1";
        transition: transform 450ms ease-out;
        -webkit-transform-origin: 5px 6px;
           -moz-transform-origin: 5px 6px;
            -ms-transform-origin: 5px 6px;
      }

      &.sync.active:before {
        content: "\f021";
        transform: rotate(180deg);
      }

      &.user > span {
        display: inline-block;
        vertical-align: middle;
      }

      &.user:before {
        content: "\f007";
      }

      &.user .lighter {
        color: lighten(@lighter-primary, 25%);
      }
    }

    span.playlist-id {
      .tagSpan();
      padding: 4px 22px 4px 8px;
      position:relative;

      i {
        color: #eee;
        position: absolute;
        right: 8px;
        top: 5px;
        cursor: pointer;

        &:hover { color: white; }
      }
    }

    .autocomplete {
      position: absolute;
      width: 60%;
      left: 15%;
      top: 31px;
      z-index: 999;

      background:white;
      border:1px solid #eee;
      border-top: none;
      border-bottom-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: black;

      ul {
        list-style: none;
        margin:0; padding:0;

        li {
          padding:6px 15px;
          line-height: normal;
          cursor: pointer;

          &.active {
            background: @gray-lightest;
          }
        }

      }
    }

  } // form

  form.expanded-search-form {
    button.expanded-lucky {
      display: inline-block;
      width: 12.5%;
      min-width: 120px;
      height: 32px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: 1px solid @gray-borders;
      border-left: 0;
      border-right: 0;
      background: @gray-lightest;
      &:hover {
        background: darken(@gray-lightest, 3%);
      }

    }

    input[type="submit"]{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .textarea-container {
      width: 47.5%;
    }
  }

  div.results-container {
    position: relative;
    background: white;
   // margin-top:@searchbar-height - 8;

    &.results-intro {
      //max-width: 900px;
      margin: 0px auto;
    }

    span.pos {
      color: #aaa;
      &:after {
        content: '.';
        margin-right: 4px;
      }
    }

    table.results-playlists,
    table.results {
      tr:hover {
        cursor: pointer;
      }
      tr td img {
        opacity: 0.8;
        transition: opacity 0.25s ease-out;
        will-change: opacity;
      }
      tr td a {
        color:#333;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
      &.results tr:hover td img {
        opacity: 1;
      
      }
      &.results tr:hover td a {
        color: black;
      
      }
      &.results-playlists tr td:hover img {
        opacity: 1;
      }
      &.results-playlists tr td:hover a {
        color: black;
      }
    }

    .results-playlists-container {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      background: @gray-lightest;
    }

    table.results-playlists {
      width: 100%;
      border:1px solid @gray-borders;

      .results-playlist {
        width: 15%;
        padding: 10px;
        vertical-align: top;
        border-radius: 4px;
        padding-top: 50px;
      }

      & > tr:first-child th {
        border-bottom: 1px solid #dfdfdf;
        text-align: left;
        position: absolute;
        left: 0;
        width: 100%;
      }

      img {
        max-width: 150px;
        display: block;
        margin-bottom: 6px;
      }

      a {
        color: black;
        font-size: 0.95em;
        text-decoration: none;
        color:#666;
        &:hover {
          color: black;
        }
      }

      i.source {
        margin-right: 4px;
        color: #bbb;
      }
    }

    .results-title {
      margin: 1em 10px 0.5em;
      font-weight: 500;
      float: left;
    }

    .add-all-btn {
      margin: 1em 10px 0.5em;
      float: right;
      padding-top: 3px;
      padding-bottom: 3px;
      min-width: 135px;
    }

    table.results {
      border-top:1px solid @gray-borders;
      border-collapse: collapse;
      display: table;
      width:100%;

      tr {
        width:100%;
        border-bottom:1px solid @gray-borders;

        &.odd {
       //   background: #fafafa;
          //border-top:1px solid @gray-borders;
        }

        &.playlist {
          background: #ffffef;

          .playlist-label {
            font-size: 0.65em;
            font-weight: 500;
            margin-left: 4px;
            color: #555;
            text-transform: uppercase;
            vertical-align: middle;
          }

          .imgTd img {
            display: none;
          }
        }

        &.initResults {
          font-size:1.75em;
          color:#ddd;
          text-align:center;
          border-bottom:0;
        }

        td {
          padding:12px;

          &.imgTd {
            width:15%;
            min-width:138px;
            img {
              width:100%;
              max-width: 135px;
            }
           }
          &.descTd {
            padding-left:12px;
            width:70%;
            vertical-align: top;

            i.source {
              margin-left: 6px;
              color: #bbb;
            }
          }
          &.buttonTd {
            width:15%;
            min-width:100px;
            text-align:right;

            button {
              max-width: 135px;
              float:right;
              clear: both;
              width:100%;
              //margin-bottom: 6px;
              display: block;
            }

            button + button {
              margin-top: 6px;
            }
          }

        }


      .entry-state {
        display: none;

        font-size: 0.8em;
        font-weight: 500;
        margin-top: 4px;

        i {
          margin-right: 4px;
        }

        &.playing {
          color: @green;
          i:before {
            content: '\f028';
          }
        }

        &.played {
          color: #cfcfcf;
          i:before {
            content: '\f00c';
          }
        }

        &.in-queue {
          color: #757575;
          i:before {
            content: '\f063';
          }
        }
      }

      &.in-queue .entry-state.in-queue,
      &.playing .entry-state.playing,
      &.played .entry-state.played {
        display: block;
      }

      } //tr
    } //table

    .mobile-expand {
      display: none;
      position: absolute;
      bottom: 0;
      background: white;
      width: 100%;
      padding: 12px 20px;
      box-shadow: 0 0 10px 10px rgba(255, 255, 255, 1);
      text-align: center;
    }
  } // results-container
}

.chat-component {
  position: fixed;
  top: 52px;
  right: 0;
  width: 25%;
  bottom: 0;
  z-index:9999;
  padding: 10px 20px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: @gray-lightest;
  border-bottom: 1px solid @gray-borders;

  .input-container {
    font-size: 1.15em;
    form {
      display: flex;
      flex-direction: row;

      input[type="text"]{
        flex-grow: 1;
        padding: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }

      .btn.btn-primary {
        margin: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-color: #dfdfdf;
        border-left: 0;
      }
    }
  }

  .message-container {
    flex-grow: 1;
    overflow: auto;
    font-size: 0.9em;
    margin-bottom: 0.5em;
    background: white;
    position: relative;

    p {
      margin: 0px;
      padding: 3px 6px;
    }

    .message-date {
      color: @gray-light;
      float: right;
    }

    .message-user {
      font-weight: 500;
      &:after { content: ':'; }
    }

    .message.message-presence {
      font-style: italic;
      color: #aaa;
    }

    .message.message-own {
      background: #f4f4f4;
    }


  }
}

// vars

@searchbar-height: 52px;
@searchinput-height: 32px;

@indigo: #3f51b5;
@darker-indigo: #26389B;

@pink: #e91e63;
@lighter-pink: lighten(@pink, 7.5%);

@teal: #009688;
@orange: #ff5722;
@red: #e51c23;
@green: #4CAF50;

@primary: @darker-indigo;
@darker-primary: darken(@primary, 5.5%);
@lighter-primary: lighten(@primary, 7.5%);

@accent: @pink;
@gray: #545455;
@gray-light: #bbb;
@gray-lightest: #f4f4f4;
@gray-borders: #ececdc;

// styles

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body, input, button, html {
  font-family: 'Roboto', sans-serif;
  font-size:14px;
}

body {
  margin: 0;
  padding: 0;

  &.modal-in {
    overflow: hidden;
  }
}

span.it { font-style: italic; }

table { font-size:1em; }

.hide { display: none; }

#app {
  overflow: auto;
  position: relative;
  min-height: 100vh;
  background: #f4f4f4;
}

.well {
  clear:both;
  margin:20px;
  padding: 12px;
  background: #fafafa;
  border-radius:2px;
}

a {
  transition: color 250ms ease-out;
  color:#00E;
  &:hover {
    color: lighten(#00E, 10%);
  }
}

.pull-left { float:left }
.pull-right { float:right }

[data-tooltip] {
  position: relative;
  z-index:200;

  &:after {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    font-size: 11.5px;
    font-weight: 500;
    line-height: 1em;
    padding: 7px;
    max-width: 235px;
    text-transform: none;
    min-width: 75%;
    opacity: 0;
    content: '';
    z-index:999;
    white-space: nowrap;

    margin-top: 6px;
  }

  &:before {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    border-bottom: 4px solid rgba(0,0,0,0.9);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    margin-top: 2px;
    margin-left: -3px;
    border-radius:2px;
    z-index:999;

  }

  &:not(.disabled):hover:after {
    display: block;
    content: attr(data-tooltip);
    transition: opacity 0.25s ease-out;
    opacity: 1;
  }

  &:not(.disabled):hover:before {
    display: block;
    content: '';
  }

  &.tooltip-right:after {
    right: 0;
    left: auto;
  }

  &.tooltip-up:after,
  &.tooltip-up:before {
    top: auto;
    bottom: 100%;
  }

  &.tooltip-up:before {
    margin-top: 0px;
    margin-bottom: 2px;
    border-bottom: 0;
    border-top: 4px solid rgba(0,0,0,0.9);
  }

  &.tooltip-up:after {
    margin-top: 0px;
    margin-bottom: 6px;
  }

  &.tooltip-big:after {
    white-space: normal;
    min-width: 200%;
    line-height: 1.2em;
  }
}

.playlist-toolbar  [data-tooltip] {
  &:after, &:before {
    top: 120%;
    outline: none;
  }
}

.playlist-toolbar button:focus {
  outline: none;
  background: #cfcfcf;
  border-color: #aaa;
  color: black;
}

p.error-p {
  font-size: 0.9em;
  color: red;
}

.cc_dialog.cc_css_reboot {
  padding: 2em !important;

  .cc_dialog_text {
    font-size: 14px;
  }
}

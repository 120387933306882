
/**
 * Breakpoints!
 *
 */

@xl-screen: 1500px;
@lg-screen: 1300px;
@tablet-screen: 1200px;
@mobile-screen: 490px;

@media all and (min-width : @mobile-screen){
  .mobile:not(.tablet) { display: none  !important; }
}

@media all and (min-width : @tablet-screen){
  .tablet { display: none  !important; }
}


@media all and (min-width : @tablet-screen){
  .results-intro { display: block; }
}

@media all and (max-width: 1300px){
  #search-component div.results-container {
    button {
      min-width: 110px;
    }
    button.add-all-btn {
      min-width: 110px;
    }
  }
  #search-component form.topbar-form .logo-label-container {
    //min-width: 11.5%;
  }
  #search-component form input[type="text"].q,
  #search-component form .autocomplete {
    width: 50%;
  }
  #search-component form .clear-search {
    left: 50%;
  }
}

// tablet
@media all and (max-width : @tablet-screen){

#search-component {
  width: 100% !important;
  padding-bottom: 0;

  div.results-container {
    button {
      padding:5px;
      margin-top: 22px;
    }
    button.add-all-btn {
      min-width: 110px;
    }
  }

  form.topbar-form {
    text-align: center;
    height: auto !important;

    .autocomplete {
      text-align: left;
    }

    .queryInputContainer {
      width: 100%;

      .clear-search {
        display: none
      }
    }

    button.action-button,
    .dropdown {
      margin: 2px;
      border: 1px solid @indigo;
      border-radius: 4px;
      height: 42px;
    }

    .dropdown .caret-container {
      line-height: 40px;
      margin: 0;
    }

    .dropdown .user-info {
      margin-top: 0;
    }

    &.sync {
      button.action-button, .dropdown {
        border-color: darken(@pink, 2.5%);
      }
    }
  }

  .desktop-join-pl.in + form {
    top: 0;
  }
}

#player-component {
  width: 100% !important;
  margin-top: 0;

  position: static;
  max-height: none;
  margin-bottom: 135px;

}

#playlist-config {
  margin-top: 106px;
  ul.tags {
    li {
      margin-bottom: 4px;
    }
  }
  .left-panel-bottom {
    margin-bottom: 10px;
  }
  .left-panel, .right-panel {
    width: 100%
  }

  .title-input-container {
    input {width:30%}
  }

  .editable {
    &:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f044";
      margin-left: 6px;
      font-size: 12px;
      padding: 0 6px;
      color: #aaa;
      vertical-align: middle;
    }
  }
}

  .footer {
    width: 100%;
    padding-bottom: 0;
    min-height: 125px;
   span {
    margin-top: 20px;
  }

  }

  .chat-component {
    position: static;
    width: 100%;
    height: 300px;
  }
}

// mobile
@media all
    and (max-width : @mobile-screen){

.desktop { display: none  !important; }

  #search-component form.topbar-form {
    padding: 0 8px !important;
  }

  #search-component button {
    padding-left: 8px;
    padding-right: 8px;
  }

  #search-component {
    min-height: 0px;

    div.results-container {
      &:not(.results-intro) {
        max-height: 555px;
        overflow: hidden;

        .mobile-expand {
          display: block;
        }

        &.expanded {
          max-height: none;
          .mobile-expand { display: none; }
        }
      }

      button {
        min-width: 65px;
        padding:5px;
      }
      .intro .titles-container {
        padding: 0 20px 0;
        background-position-y: 50%;
      }

      table.results-playlists {
        .results-playlist { 
          padding-bottom: 0;
        }

        img {
          max-width: 90px;
        }

        a {
          font-size: 0.85em;
        }
      }
    }

    form.topbar-form {
      top: 0 !important;
      height: auto !important;
      font-size: 0.85em;
      text-align: center;
      .logo-label-container {
        min-width: 0;
      }
      .queryInputContainer {
        width: 100%;

        button.expanded-search {
          display: none;
        }
        input[type="text"].q,
        .autocomplete {
          width: 75%;
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        input[type="submit"]{
          width: 25%;
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .clear-search {
          display: none
        }
      }

      button.action-button,
      .dropdown {
        margin: 2px;
        border: 1px solid @indigo;
        height: 42px;
      }

      .dropdown .caret-container {
        line-height: 40px;
        margin: 0;
      }

    }

    .teasers .box {
      width: 100%;
      margin: 25px 0 10px ;
    }

  } // search-component

  .footer .pull-right {
    margin: 25px auto 10px;
    float:none;
    text-align: center;
  }

  .footer span {
    margin-top: 20px;
  }

  .footer .privacy-policy-link {
    display:block;
    margin: 10px 0 0 0 !important;
  }

  #playlist-config {
    margin-top: 106px;

    div.panel {
      height: auto;
    }
    ul.tags {
      li {
        margin-bottom: 4px;
      }
    }
    .left-panel {
      width: 100%;
      float: none;

      .left-panel-contents {
        width: 100%;
        float: none;

        input[type="text"]:not(.input-short), textarea {
          width: 100%;
        }
        input[type="text"].input-short {
          width: 55%;
        }
        button {
          width: auto;
          margin-top: 1px;
          margin-bottom: 6px;
          margin-left: 0 !important;
        }
      }

      div.img-container {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

    }
    .left-panel-bottom {
      margin-bottom: 10px;
    }
    .right-panel {
      padding: 0px;
      width: 100%;
      float: none;

    }

    .title-input-container {
      input {width:50%}
    }

    .editable {
      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f044";
        margin-left: 6px;
        font-size: 12px;
        padding: 0 6px;
        color: #aaa;
        vertical-align: middle;
      }
    }
  }

  .playlist-block {
    .image-container .info {
      font-size: 0.8em;
    }
    .playlist-title {
      font-size: 1em;
      overflow: hidden;
    }
    .image-container {
      height: 75px;
      img {
        top: 0;
        left: 0;
        transform: translateY(0);
      }
    }
  }

  .results-playlists .results-playlist-controls {
    float: none;
    text-align: right;

    input {
      display: block;
      margin: 8px 0;
      padding: 8px 12px;
      width: 100%;
    }

  }

  .cc_dialog.cc_css_reboot {
    padding: 1em 1.5em !important;
    max-width: 100% !important;

    .cc_dialog_text {
      font-size: 12px;
    }
  }

} // mobile


// Animations
/* Chrome, Safari, Opera */
@-webkit-keyframes saved {
  0% {
    opacity:0;
    transform: rotate(45deg);
  }
  40% {
    opacity:1;
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Standard syntax */
@keyframes saved {
  0% {
    opacity:0;
    transform: rotate(45deg);
  }
  50% {
    opacity:1;
  }
  100% {
    transform: rotate(0deg);
  }
}

.fade-enter {
  opacity:0.01;
  transition: opacity .5s ease-out;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade-leave {
  transition: opacity .5s ease-in;
}

.fade-leave.fade-leave-active {
  opacity:0;
}


.results-playlists {
  
  .page-title {
    line-height: 30px;
    margin: 0;

    small {
      color: @gray-light;
    }
  }

  .page-number {
    display: block;
    margin-bottom: 1.75em;
    color: #9a9a9a;
  }

  .blocks-container > h3 {
    margin: 1.5% 0;
  }

  .playlist-search {
    padding:10px;
    text-align: center;
    background: #eee;

    input {
      font-size: 1.1em;
      min-width: 75%;
    }
  }

  .playlist-container {
    overflow: auto;
  }

  .playlist-pagination {
    margin-top: 20px;

    a {
      //margin:0 6px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #eee;
      padding: 8px;
      border-radius: 4px;
      text-decoration: none;

      &.disabled {
        color:#aaa;
        text-decoration: none;
        cursor: default;
      }

      &:not(.disabled):hover {
        background: #eee;
      }
    }
  }

  .special-message {
    padding: 20px;
  }

  .results-playlist-controls {
    float: right;
    input {
      padding: 6px 8px;
      min-width: 250px;
      margin-right: 4px;
    }
  }

}



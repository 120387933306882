.topBar() {
  display: block;
  position: fixed; //was: absolute
  z-index: 999;
  background: @primary;
  color:white;
  top:0;
  left:0;
  padding:0 18px 0 18px;
  line-height: @searchbar-height;
  height: @searchbar-height;
  width:100%;

  transform: translateZ(0);

  transition: background 250ms ease-out;
  will-change: background;

  border-bottom:2px solid darken(@darker-primary, 5%);

  &.sync {
    background: @lighter-pink;
    border-bottom:2px solid darken(@pink, 5%);

    button.action-button,
    .dropdown .button-container,
    .dropdown .caret-container {
      background: @lighter-pink;

      &:hover {
        background: @pink;
      }
    }

    .dropdown .button-container:hover,
    .dropdown .caret-container:hover {
      border-color: darken(@pink, 0%);
    }
  }

  span.logo {
    display: inline-block;
    text-transform: uppercase;
    text-align:left;
    font-weight: 600;
    font-size: 1em;
    line-height: 32px;
    vertical-align: middle;
    padding-right: 2px;

    a {
      color: white;
      text-decoration: none;
    }
  }

  .label {
    margin: 0 12px;
    border-radius: 6px;
    padding:3px 6px;
    background: rgba(255,255,255,0.5);
    font-size: 0.7em;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: normal;
    vertical-align: middle;
  }

  .logo-label-container {
    text-align: left;
    min-width: 100px;
    display:inline-block;
    &.mobile {
      float: left;
      line-height: 42px;
    }
  }

  input:disabled {
    background: #eaeaea !important;
  }

  .toolbox.disabled {
    visibility: hidden;
  }

  .user-info {
    display: inline-block;
    line-height: 1em;
    margin-top: 3px;

    .user-info-pic-container {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;

      img {
        width: 100%;
      }
    }
    .user-info-name {
      display: inline-block;
      vertical-align: middle;
      color: white;
      margin-left: 10px;
    }
  }

}

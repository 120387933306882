@spotify: #1db954;
@facebook: #3b5998;

form.login-form {
  margin-bottom: 20px;

  & > div {
    margin: 10px 0;

    label {
      display: block;
    }

    input {
      min-width: 200px;
    }
  }

}

.login-with {
  width: 100%;
  display: block;
  margin: 10px auto;
  padding: 10px;
  border-radius: 30px;
  border: 0;

  line-height: 16px;

  transition: background 200ms ease-in;

  &:hover {
    cursor: pointer;
  }

  &.login-with--facebook {
    background: @facebook;
    color: white;
    &:hover {
      background: lighten(@facebook, 7.5%);
    }
  }

  &.login-with--spotify {
    background: @spotify;
    color: white;
    &:hover {
      background: lighten(@spotify, 5%);
    }
  }

  &.login-with--google {
    padding: 9px;
    color: black;
    border: 1px solid #cfcfcf;
  }

  &.login-with--eth {
    padding: 9px;
    color: white;
    background: #1169DE;
    border: 1px solid darken(#1169DE, 7.5%);
    &:hover {
      background: lighten(#1169DE, 5%);
    }
  }

  & > img {
    height: 16px;
    vertical-align: bottom;
    margin-right: 6px;
  }
}



input[type="text"],
textarea {
  padding:5px;
  font-size:1.1em;
  border-radius:2px;
  //outline: none;
  border:1px solid @gray-borders;
  font-size:0.9em;
  box-shadow: 0 0 0 black;
}

textarea {
  font-size: 0.9em;
}

input.q {
  font-size: 1em;
}

.block-input {
  display: block;
  width: 100%;
}

input.form-text-input {
  border: 1px solid darken(@gray-lightest, 5%);
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 1.25em;
}

input.form-text-input[disabled] {
  background: #f4f4f4;
  color: #999;
}

input.input-error {
  border-color: red;
}

.toast-container {
  font-size: 0.9em;
}

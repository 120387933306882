
.user-details {
  margin: 20px;
  padding: 20px;
  overflow: auto;

  .user-avatar {
    border-radius: 100%;
    width: 128px;
    height: 128px;
    background: #dfdfdf;
  }

  .user-name {
    margin: 0;
    margin-top: 1em;
  }

  .user-playlist-count {
    float:left;
    margin-top: 0.4em;
    color: #AAA;
  }

}

.linkBox() {
  position: fixed;
  bottom:15px;
  min-width:136px;
  right:0;
  color:#333;
  padding:4px 10px 4px 22px;
  font-size:12px;
  border:1px solid #cfcfcf;
  background: @gray-lightest;
  text-decoration: none;

  &:hover {background:darken(@gray-lightest, 5%);}

  &::before {
    position:absolute;
    left: 4px;
    top: 0;
    display:inline-block;
    width:100%;
    height: 100%;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f09b";
    font-size: 16px;
    line-height: 26px;
  }
}

#github-link {
  .linkBox();
  z-index: 100;
}

input[type="submit"],
button,
a.btn {
  background: white;
  border: 1px solid @gray-borders;
  padding: 6px 12px;
  z-index: 2;
  font-weight: 400;
  font-size: 0.9em;
  //border-radius: 4px;
  border-radius: 0;
  //outline: none;
  transition: box-shadow 200ms ease-out, background 200ms ease-out;
  text-decoration: none;

  -webkit-appearance: none;

  &:hover {
    background: @gray-lightest;
  }

  &:active {
   box-shadow:inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
  }

  &.primary {
    border:1px solid @accent;
    color: @accent;

    &:hover {
      background: @accent;
      color:white;
    }

    &:active {
      box-shadow:inset 0 0 100px 100px rgba(0, 0, 0, 0.5);
    }
  }

  &.btn-secondary {
    background-color: @lighter-primary;
    border-color: @primary;
    color: white;
  }

  &.flat {
    border:0;
    background:transparent;
  }

  &.btn-outline {
    background: transparent;
    color: black;
  }


  &.btn-underline {
    position: relative;
    position: block;
    background-color: transparent;
    border: 0;
    color: @primary;
    transition: color 175ms ease-out;
    padding: 0;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 1.5em;
    padding-top: 4px;
    padding-bottom: 4px;

    &:after {
      width: 100%;
      position: absolute;
      bottom: -4px;
      left: 0;
      display: block;
      content: ' ';
      z-index: -1;
      border-bottom: 2px solid @lighter-primary;
      transition: border-bottom-color 175ms ease-out,
        border-bottom-width 175ms ease-out;
    }

    &:hover {
      color: white;
      &:after {
        border-bottom-color: darken(@lighter-primary, 15%); 
        border-bottom-width: 30px;
      }
    }
  }

}

input[type="submit"] {
  padding: 4px;
}

.btn-group {
  display: inline-block;

  button {
    padding: 4px 12px;
    border-radius: 0;
  }

  button:first-child:not(:only-child) {
    border-right: 0;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
  }

  button:last-child:not(:only-child) {
    border-left: 0;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
  }

  button.active {
    background: @indigo;
    color: white;
  }
}

input[type="submit"].btn,
.btn {
  padding: 7px 18px;
}

.btn-transparent {
  border: 0;
}

input[type="submit"].btn-primary,
button.btn-primary,
a.btn-primary {
  background: @accent;
  color: white;
}

.footer a.twitter-follow-button {
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 0px 8px;
  background: #1b95e0;
  border-radius: 3px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
  &:hover, &:active {
    background: #0c7abf;
  }
  i {
    position: relative;
    top: 3px;
    vertical-align: top;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E)
  }
}


a.btn.space-right {
  margin-right: 4px;
}


@import 'media-queries.less';

// no results yet!

.intro {
  text-align: center;
  background-color: @gray-lightest;
  overflow:auto;

  .titles-container {
    color: white;
    background-image:url('/static/images/vinil.jpg');
    background-size: cover;
    background-position-y: 0%;
    background-attachment: fixed;
    position: relative;

    height: 250px;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      position: absolute;
      top:0; left:0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.65);
      content: ' ';
    }

    & > div {
      position: relative;

      h1 {
        font-weight: 300;
        font-size:1.75em;
        margin: 0;
      }
      .subtitle {
        margin: 1em 0;
        font-size:1.1em;
        font-weight: 300;
        color: #aaa;
        span.black {
          color: white;
          margin: 0 8px;
        }
      }
    }
  }
  ul {
    list-style: none;
    padding: 0px;

    li {
      margin:6px 0;
    }

  }

  .box {
    width:33%;
    padding:0 10px;
    color:#666;
    float:left;
    position:relative;
    z-index:50;
    text-align: left;

    h3 {
      margin: 0;
      color: black;
      font-weight: 400;
      clear:both;
      height: 45px;
      border-bottom:3px solid @gray-lightest;
      span {
        position:relative;
        z-index:55;
        max-width: 80%;
        display: inline-block;
      }
    }

    i {
      margin-right: 8px;
      float: right;
      color:@gray-lightest;
      font-size:2em;
      position:absolute;
      right:10px;
      top:9px;
      z-index:50;

      top: 0px;
      padding: 6px 7px 2px 7px;
      color: darken(@gray-lightest, 5%);
      //background: @gray-lightest;
      //border-bottom: 1px solid darken(@gray-lightest, 5%);
    }
    p {
      color: #999;
      font-weight: 100;
      line-height: 1.5em;
      a { font-weight: 400; }
      strong { font-weight:400; }
    }
  }

  .features {
    overflow: auto;
    padding: 20px 10px;
    background:white;
    border-top: 1px solid @gray-borders;
  }

}

.horizontal-list {
  margin: 20px;
  background: white;
  border:1px solid @gray-borders;
}

.horizontal-list {
  text-align: left;
  padding: 20px;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.04);

  .horizontal-list-title {
    overflow: auto;
    margin-bottom: 20px;
    line-height: 29px;
    padding-bottom: 4px;

    & > h3 {
      float: left;
      margin: 0;
    }
  }

  & > h3 {
    margin-top: 0;
    font-size: 15px;
    color: #333;
  }

  .blocks-container {
    clear:both;
    list-style: none;

    .block {
      width: 33.3%;
      margin: 1px 0 0;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      transition: background 250ms ease-in;
      opacity: 0.825;
      will-change: opacity;
      transition: opacity 250ms ease-in-out;

      img {
        min-width: 100%;
        height: 150%;
        display: block;
      }

      .image-container {
      }

      &:hover {
        background: @gray-lightest;
        opacity: 1;
      }
    }

    @media (min-width: @lg-screen){
      .block {
        width: 25%
      }
    }

    @media (min-width: @xl-screen){
      .block {
        width: 20%
      }
    }
  }
}


.trending-playlists {
  text-align: left;

  h3 {
    margin-top:0;
  }
}

.recent-searches {
  li {
    display: inline-block;
    padding-right: 10px;

    &:after {
      content: '•';
      color: #ddd;
      padding-left: 10px;
    }
  }
}

.username-form-container {
  display: block;
  overflow: auto;
  min-height: 250px;

  .username-thumbnail-container {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;

    img { width: 100% }

  }

  label {
    display: block;
    margin: 1em 0 0.25em;
    font-size: 0.9em;
  }

  p.username-form-title {
    margin-bottom: 40px;
  }

}

@import (reference) './global.less';

@gray-bg: #f5f5f5;

.playlist-block {
  position: relative;
  font-size:0.9em;
  text-decoration: none;
  color: black;
  margin-top: 0 !important;
  padding: 2px;

  .image-container {
    position: relative;
    height: 145px;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    background: transparent !important;
    .text {
      background: @gray-bg;
    }
  }

  .info, .playlist-open {
    position: absolute;
    color: #eee;
    padding: 2px;
    transition: color 250ms ease-in;
  }

  .info {
    background: rgba(0,0,0,0.5);
    top:0;
    left:0;
    span {
      margin: 0 4px;
      i { margin-right: 2px;}
      i.fa-music { margin-right: 4px; }
    }
    opacity: 0.85;
    padding: 4px;
    will-change: opacity;
    transition: opacity 150ms ease-out;
    width: 100%;
  }

  &:hover .info {
    opacity: 0.95;
  }

  .playlist-title {
    font-family: 'Lato', sans-serif;
    width: 100%;
    min-height: 43px;
    font-weight: 700;
    font-size: 1.33em;
    margin: 0;
    overflow: hidden;

    small {
      display: block;
      font-weight: 300;
      font-size: 0.8em;
      font-style: normal;
      span {
        font-style: italic;
      }
    }
  }

  .playlist-mobile-title {
    margin-top: 0;
    word-wrap: break-word;
  }

  .playlist-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    span.fa {
      color: #aaa;
      margin-right: 2px;
    }
  }

  .playlist-open {
    top:0;
    right:0;
    padding-top: 1px;
    padding-right: 3px;
    font-size: 1.4em;

    &:hover {
      color: white;
    }
  }

  .playlist-id, .playlist-created {
    color: #aaa;
    font-weight: 300;
    font-size: 0.9em;
    word-wrap: break-word;
    margin-top: 1em;
  }

  .playlist-created {
    margin: 0;
    margin-top: 4px;
  }

  .text {
    padding: 10px;
    margin-top: 1px;
    min-height: 80px;
  }

  p {
    margin: 0
  }

  .playlist-match-query {
    font-size: 0.89em;
    line-height: 1.33em;
    margin: 0px -4px 8px;
    display: inline-block;
    padding: 4px;
    background: #FFFFCA;
    color: #787d05;
    font-weight: 500;
    & > span.em { font-weight: 200; }
  }

  &:hover {
    color: black;
    //background: #f4f4f4;
    // box-shadow: 0 0 2px 2px rgba(0,0,0,0.12);

  }

}

.dropdown {
  position: relative;
  transition: box-shadow 200ms ease-out, background 200ms ease-out;
  vertical-align: top;

  & > div {
    display: flex;
    align-items: stretch;
  }

  .button-container,
  .caret-container {
    border: 0;
    border-radius: 0;
    padding: 0px 6px;
    transition: background 200ms ease-out;
    cursor: pointer;
  }

  .button-container {
    border-right: 1px solid transparent;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    &:hover {
      border-right: 1px solid @primary;
    }
  }

  .caret-container {
    display: inline-block;
    padding: 0px 6px;
    vertical-align: middle;
    border-left: 1px solid transparent;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: white;

    &:hover {
      border-left: 1px solid @primary;
    }
  }

  ul {
    position: absolute;
    top: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid @gray-borders;
    border-top: 0;
    margin-top: 3px;
  }

  .open ul {
    display: block;
  }

  li a {
    text-decoration: none;
    color: black;
    display: block;
    padding: 6px 12px;
    margin: 6px 0;
    line-height: 1.5em;

    &:hover {
      background: #eee;
    }
  }
}

.topbar-form .dropdown {

  ul { font-size: 0.9em; }

  .button-container,
  .caret-container {
    background: transparent;
    &:hover {
      background: @lighter-primary;
    }
  }

  .caret-container {
    line-height: 47px;
    margin-top: 3px;
  }
}

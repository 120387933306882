
// Welcome to styles.less!

// variables and global styles
@import "global.less";

@import "top-bar.less";
@import "player-component.less";
@import "search-component.less";
@import "intro-component.less";
@import "playlist-config.less";
@import "footer.less";

@import "playlists.less";

@import "Modal.less";

// admin backoffice
@import "admin.less";

// buttons, inputs
@import "buttons.less";
@import "inputs.less";

// feddback component (todo)
@import "feedback.less";

// github, ...
@import "link-box.less";

@import "tooltip.less";

// media queries
@import "media-queries.less";

@import "login-modal.less";
@import "username-modal.less";
@import "dropdown.less";

.Toastify__toast-container {
  width: auto;
}

.Toastify__toast {
  max-width: 450px;
  min-height: 36px !important;
  padding: 4px 10px 4px 10px;
  font-weight: 500;
  border-radius: 4px;
}

.Toastify__toast--default {
  color: #545b01;
  background: #fdffdd;
}

.Toastify__toast--error {
  background: #d62d1c;
}

.Toastify__toast-body { 
  padding: 5px 15px 5px 5px;
  line-height: 1.35em;
}

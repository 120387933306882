#feedback-form {
  z-index:100;

  .backdrop {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.4);
    z-index:100;
  }

  .form {
    position:absolute;
    top:20%;
    width:50%;
    left:25%;

    box-shadow: 0 0 2px 2px rgba(0,0,0,0.15);
    transition: opacity 250px ease-out;

    padding:20px;
    background: @gray-lightest;
    border:1px solid @gray-borders;

    z-index:150;

    h3 {
      margin: 0;
      color: black;
      font-weight: 400;
    }
  }
}

#feedback-link {
  .linkBox();
  bottom:44px;
  &::before {
    content: "\f164";
    font-size: 14px;
    line-height: 24px;
  }

}